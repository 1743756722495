export const SELECTED_REQUESTS_STORAGE_TOKEN = 'SELECTED_REQUESTS'
export const SELECTED_ACTION_TYPE_STORAGE_TOKEN = 'SELECTED_ACTION'
export const REQUEST_COMMENTS = 'REQUEST_COMMENTS'
export const POSTED_REQUESTS_STORAGE_TOKEN = 'POSTED_REQUESTS'
export const INIT_INDICATOR = 'INIT_INDICATOR'

export function getStoredInitIndicator(): string {
  if (window !== undefined) {
    try {
      const storedInitIndicator = window.sessionStorage.getItem(INIT_INDICATOR) || ''
      return storedInitIndicator
    } catch (e) {
      return ''
    }
  }
  return ''
}

export function setStoredInitIndicator() {
  if (window !== undefined) {
    window.sessionStorage.setItem(INIT_INDICATOR, 'session_init_true')
  }
}

export function clearStoredInitIndicator() {
  if (window !== undefined) {
    window.sessionStorage.removeItem(INIT_INDICATOR)
  }
}

export function getStoredRequestIds(): string[] | [] {
  if (window !== undefined) {
    try {
      const storedRequestIds = window.sessionStorage.getItem(SELECTED_REQUESTS_STORAGE_TOKEN)
      return storedRequestIds ? <string[] | []>JSON.parse(storedRequestIds) : []
    } catch (e) {
      return []
    }
  }
  return []
}

export function getStoredPostedRequestIds(): string[] | [] {
  if (window !== undefined) {
    try {
      const storedRequestIds = window.sessionStorage.getItem(POSTED_REQUESTS_STORAGE_TOKEN)
      return storedRequestIds ? <string[] | []>JSON.parse(storedRequestIds) : []
    } catch (e) {
      return []
    }
  }
  return []
}

export function getStoredActionType() {
  if (window !== undefined) {
    const storedActionType = window.sessionStorage.getItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN)
    return storedActionType || ''
  }
  return ''
}

export function getStoredComments() {
  if (window !== undefined) {
    const storedComments = window.sessionStorage.getItem(REQUEST_COMMENTS)
    return storedComments || ''
  }
  return ''
}

export function setStoredRequestIds(requestIdsToStore: any[] | []) {
  if (window !== undefined) {
    window.sessionStorage.setItem(SELECTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestIdsToStore))
  }
}

export function setStoredPostedRequestIds(requestIdsToStore: any[] | []) {
  if (window !== undefined) {
    try {
      const postedRequestIds = getStoredPostedRequestIds()
      window.sessionStorage.setItem(
        POSTED_REQUESTS_STORAGE_TOKEN,
        JSON.stringify([...new Set([...postedRequestIds, ...requestIdsToStore])])
      )
    } catch (e) {
      window.sessionStorage.setItem(POSTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestIdsToStore))
    }
  }
}

export function setStoredActionType(actionTypeToStore = '') {
  if (window !== undefined) {
    window.sessionStorage.setItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN, actionTypeToStore)
  }
}

export function setStoredComments(commentsToStore = '') {
  if (window !== undefined) {
    window.sessionStorage.setItem(REQUEST_COMMENTS, commentsToStore)
  }
}

export const clearStoredTokens = () => {
  if (window !== undefined) {
    window.sessionStorage.removeItem(SELECTED_REQUESTS_STORAGE_TOKEN)
    window.sessionStorage.removeItem(SELECTED_ACTION_TYPE_STORAGE_TOKEN)
    window.sessionStorage.removeItem(POSTED_REQUESTS_STORAGE_TOKEN)
    window.sessionStorage.removeItem(REQUEST_COMMENTS)
  }
}

// export function getStoredAuthRequests(): any {
//   if (window !== undefined) {
//     try {
//       const storedSelectedRequests: string | null = window.sessionStorage.getItem(
//         SELECTED_REQUESTS_STORAGE_TOKEN
//       )
//       if (storedSelectedRequests && JSON.parse(storedSelectedRequests)) {
//         return JSON.parse(storedSelectedRequests) || {}
//       }
//       return {}
//     } catch (e) {
//       return {}
//     }
//   }
//   return {}
// }

// export function setStoredAuthRequestsBatch(requests: string[] | [], actionType = '', status = '') {
//   if (window !== undefined) {
//     const storedSelectedRequests = getStoredAuthRequests()
//     const requestsToStore: any = { ...storedSelectedRequests }
//     requests.forEach((req) => {
//       requestsToStore[req] = {
//         status,
//         actionType,
//         requestNumber: req,
//       }
//     })
//     window.sessionStorage.setItem(SELECTED_REQUESTS_STORAGE_TOKEN, JSON.stringify(requestsToStore))
//   }
// }

// export function getStoredRequest(requestNumber: string): { string: any } | null {
//   if (window !== undefined) {
//     // this 'any' trick is a hack to get around my frustration with typescript ATM
//     const storedSelectedRequests = getStoredAuthRequests()
//     if (!!requestNumber && !!storedSelectedRequests[requestNumber]) {
//       return storedSelectedRequests[requestNumber]
//     }
//   }
//   return null
// }

export const getPaymentColor = (purposeType: any, RequestTypes: any, paymentStatus: any) => {
  const color = RequestTypes.map((item: any) => {
    if (item.PaymentType === 'Comp' || item.PaymentType === 'InternalCharge') {
      return 'black'
    } else if (item.PaymentType === 'CreditCard') {
      return 'green'
    } else if (item.PaymentType === 'External' && paymentStatus === 'Received') {
      return 'green'
    } else {
      return 'red'
    }
  })

  return color[0]
}

export const hasCreditCardOrExternal = (request: any) =>
  request?.RequestTypes?.some(
    (item: any) => item.PaymentType === 'CreditCard' || item.PaymentType === 'External'
  )

export const getExportCSVHeaders = () => [
  { label: 'Request Number', key: 'RequestNumber' },
  { label: 'Event Date', key: 'EventDate' },
  { label: 'Event Name', key: 'EventName' },
  { label: 'Requested on behalf', key: 'RequestedFor' },
  { label: 'Title', key: 'Title' },
  { label: 'Department Name', key: 'DepartmentName' },
  { label: 'Recipient', key: 'Recipient' },
  { label: 'Total Tickets', key: 'TotalTickets' },
  { label: 'Purpose Type', key: 'PurposeType' },
  { label: 'Payment Type', key: 'PaymentType' },
  { label: 'Priority', key: 'Priority' },
]

const getPaymentType = (data: any) => {
  const type = data?.map((item: any) => item?.PaymentType)

  return type.join(' , ')
}

export const csvData = (data: any) => {
  return data?.map((req: any) => ({
    RequestNumber: req.RequestNumber,
    EventDate: req?.Event?.EventDateTime,
    EventName: req?.Event?.EventName,
    RequestedFor: req.RequestedFor,
    Title: req?.Employee?.Title,
    DepartmentName: req?.Employee?.Department?.DepartmentName,
    Recipient: `${req.FirstName} ${req.LastName}`,
    TotalTickets: req.TotalTickets?.toString(),
    PurposeType: req.PurposeType || '',
    PaymentType: getPaymentType(req?.RequestTypes),
    Priority: req.RequestPriority || '',
  }))
}
